import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ServiceV3 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <div id="services"className="service-area mg-top--150">
		<p className="row justify-content-center">Our Services</p>
		<h2 className="row section-title aligncenter1 justify-content-center">How we can help you</h2>
			  <div className="container">
			    <div className="row justify-content-center">
			      <div className="col-lg-4 col-md-10">
			        <div className="single-service-wrap style-2 small-padding" >
			          <div className="thumb">
			            {/* <img src={publicUrl+"assets/img/service/1.png"} alt="icon" /> */}
			          </div>
			          <div className="single-service-details">
			            <h4><a href="#">Startup Funding</a></h4>
			            <p>Connects with the Investors around you to fund your Startup</p>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4 col-md-10">
			        <div className="single-service-wrap style-2 small-padding">
			          <div className="thumb">
			            {/* <img src={publicUrl+"assets/img/service/2.png"} alt="icon" /> */}
			          </div>
			          <div className="single-service-details">
			            <h4><a href="#">Startup Services</a></h4>
			            {/* <p>Lorem ipsum dolor sit consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</p> */}
						<p>One-stop-shop offering end-to-end solutions of startup services.
							{/* <ul>
							<li>Company Registration</li>
							<li>Trademark Registration</li>
							<li>GST Filings</li>
						</ul> */}
						</p>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4 col-md-10">
			        <div className="single-service-wrap style-2 small-padding" >
			          <div className="thumb">
			            {/* <img src={publicUrl+"assets/img/service/3.png"} alt="icon" /> */}
			          </div>
			          <div className="single-service-details">
			            <h4><a href="#">Legal Services</a></h4>
			            <p>We provide legal and compliance
solutions to protect your business.</p>
			          </div>
			        </div>
			      </div>
				  </div>
			  </div>
			  <div className="container" style={{padding:"20px"}}>
			    <div className="row justify-content-center">		
				  <div className="col-lg-4 col-md-10">
			        <div className="single-service-wrap style-2 small-padding" >
			          <div className="thumb">
			            {/* <img src={publicUrl+"assets/img/service/1.png"} alt="icon" /> */}
			          </div>
			          <div className="single-service-details">
			            <h4><a href="#">Investment Scope </a></h4>
			            <p>Invest in Businesses from your Locality and Earn good Returns</p>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4 col-md-10">
			        <div className="single-service-wrap style-2 small-padding">
			          <div className="thumb">
			            {/* <img src={publicUrl+"assets/img/service/2.png"} alt="icon" /> */}
			          </div>
			          <div className="single-service-details">
			            <h4><a href="#">Software Solutions</a></h4>
			            <p>Helping businesses to boost and stay ahead with top-notch software.</p>
						{/* <p><ul>
							<li>Company Registration</li>
							<li>Trademark Registration</li>
							<li>GST Filings</li>
						</ul>
						</p> */}
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4 col-md-10">
			        <div className="single-service-wrap style-2 small-padding" >
			          <div className="thumb">
			            {/* <img src={publicUrl+"assets/img/service/3.png"} alt="icon" /> */}
			          </div>
			          <div className="single-service-details">
			            <h4><a href="#">PR & Branding</a></h4>
			            <p>Make your brand standout with Effective PR and Branding.</p>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>

        }
}

export default ServiceV3