import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import { getName } from '../../globalfunction';
// import parse from 'html-react-parser';

class BannerV2 extends Component {
	componentDidMount() {

		const $ = window.$;
   
		// $( 'body' ).addClass( 'body-bg' );
		$('.owl-carousel').owlCarousel({
			margin: 30,
			loop:true,
			responsiveClass: true,
			stagePadding: 0,
			nav: true,
			dots: true,
			autoplay: 1000,
			singleItem:true,
			items: 1,
			slideSpeed: 100,
			navText: ["Prev", "Next"],
			smartSpeed: 1000,
		})
	}

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="banner-area banner-area-2 banner-area-bg" 
	// style={{backgroundImage: `linear-gradient(to right, rgba(17, 147, 253, 0.582) , rgba(72, 105, 253, 0.801))`}}
	>
			  
			  {/* <div className="container"> */}
			  <div className="banner-slider owl-carousel">
			      <div className="item" style={{background: 'url('+publicUrl+'assets/img/banner/img3.jpeg)', backgroundSize:'cover', width:'100%', height:'100%'}}>
			        <div className="banner-area-inner" style={{width: "100%",display: "block"}}>
			          <div className="row justify-content-center">
			            <div className="col-lg-12">
			              <div className="banner-inner text-center">
			               <h2>Deploy your Startup Dream
								<br/>
								with
								<br/>
								Launch A Venture
							</h2>
							<div className="line" />
							<ul>
			           {getName() ? 
					  <>
					  {/* <li className="menu-item-has-children current-menu-item">
					  		<div className="btn btn-base">{getName()}</div>
						</li> */}
						</>
					  :
			          <li><Link className="btn btn-base" to="/sign-in">Get Started</Link></li>}
			        </ul>

			              </div>
			            </div>
			          </div>
			        </div>
			        {/* <div className="banner-meta-inner">
			          <h4>Mordan Appertment In <br /> The City Center</h4>
			          <img src={publicUrl+"assets/img/icon/location.png"} alt="img" />
			          <span>420 Love Sreet 133/2 Mirpur City, Dhaka</span>
			          <div className="btn btn-blue">$ 80,650.00</div>
			        </div> */}
			      </div>
			      <div className="item" style={{background: 'url('+publicUrl+'assets/img/banner/img4.jpeg)', backgroundSize:'cover', width:'100%', height:'100%'}}>
			        <div className="banner-area-inner" style={{width: "100%",display: "block"}}>
			          <div className="row justify-content-center">
			            <div className="col-lg-12">
			              <div className="banner-inner text-center">
			               <h2>Grow your Venture Exponentially
								<br/>
								with
								<br/>
								Launch A Venture
							</h2>
							<div className="line" />
							<ul>
			           {getName() ? 
					  <>
					  {/* <li className="menu-item-has-children current-menu-item">
					  		<div className="btn btn-base">{getName()}</div>
						</li> */}
						</>
					  :
			          <li><Link className="btn btn-base" to="/sign-in">Know More</Link></li>}
			        </ul>

			              </div>
			            </div>
			          </div>
			        </div>
			        {/* <div className="banner-meta-inner">
			          <h4>Mordan Appertment In <br /> The City Center</h4>
			          <img src={publicUrl+"assets/img/icon/location.png"} alt="img" />
			          <span>420 Love Sreet 133/2 Mirpur City, Dhaka</span>
			          <div className="btn btn-blue">$ 80,650.00</div>
			        </div> */}
			      </div>
			      <div className="item" style={{background: 'url('+publicUrl+'assets/img/banner/img5.jpeg)', backgroundSize:'cover', width:'100%', height:'100%'}}>
			        <div className="banner-area-inner" style={{width: "100%",display: "block"}}>
			          <div className="row justify-content-center">
			            <div className="col-lg-12">
			              <div className="banner-inner text-center">
			               <h2>Discover and Invest in Local Startups
								<br/>
								with
								<br/>
								Unmatched Returns
							</h2>
							<div className="line" />
							<ul>
			           {getName() ? 
					  <>
					  {/* <li className="menu-item-has-children current-menu-item">
					  		<div className="btn btn-base">{getName()}</div>
						</li> */}
						</>
					  :
			          <li><Link className="btn btn-base" to="/sign-in">Discover Now</Link></li>}
			        </ul>

			              </div>
			            </div>
			          </div>
			        </div>
			        {/* <div className="banner-meta-inner">
			          <h4>Mordan Appertment In <br /> The City Center</h4>
			          <img src={publicUrl+"assets/img/icon/location.png"} alt="img" />
			          <span>420 Love Sreet 133/2 Mirpur City, Dhaka</span>
			          <div className="btn btn-blue">$ 80,650.00</div>
			        </div> */}
			      </div>
			    </div>   
			  </div>
			// </div>
			 

        }
}

export default BannerV2