import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {Table, Button} from 'reactstrap';
import parse from 'html-react-parser';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { serviceUrl, tokenName, iName } from '../../constant';
import { getToken } from '../../globalfunction';

class Funding extends Component {
	state={
		project:[],
		// this.onSubmit()=this.onSubmit.bind(this),
	}
	async investedListFetch(){
		getToken();
		try {
			let project2 = await axios.get(`${serviceUrl}prj/log/${this.props.match.params.id}`);
			const project = project2.data.data.items[0].upgrades;
		  	this.setState ({project});
		  	console.log(project);
			  console.log(this.state.project[0].title);
		
			// Work with the response...
		} catch (err) {
			if (err.response) {
				// The client was given an error response (5xx, 4xx)
				console.log(err.response.data);
       			console.log(err.response.status);
        		console.log(err.response.headers);
			} else if (err.request) {
				// The client never received a response, and the request was never left
				console.log(err.request);
			} else {
				// Anything else
				console.log('Error', err.message);
			}
		}
	  }
	 

	componentDidMount() {
		this.investedListFetch();

     const $ = window.$;

     $( 'body' ).addClass( 'bg-gray' );

 	}

    render() {

        // let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="signin-page-area pd-top-100 ">
			  <div className="container">
			  <h4 >Project Name:- <strong> {this.state.project.length>0?this.state.project[0].title:"" } </strong></h4> 
			  <h6>City:- <strong> {this.state.project.length>0?this.state.project[0].city:"" } </strong></h6>
			  {this.state.project.length>0?<p><Button color="primary"><Link to={`/f/project/${this.state.project[0].prj_id}`}>Visit</Link></Button></p>:""}
			    {/* <hr/> */}
				<h4>URL:-<a href={this.state.project.length>0?this.state.project[0].link:""} target='_blank' rel="noreferrer">{this.state.project.length>0?this.state.project[0].link:""}</a></h4>
				<div className="row justify-content-center">
					
				{/* <ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
				/> */}
				
				<p>{this.state.project.length>0?this.state.project[0].description:""}</p>
				
			      {/* <Table responsive>
					<thead>
						<tr>
						<th>
							Date
						</th>
						<th>
							Amount Invested
						</th>
						<th>
							Partake
						</th>
						</tr>
					</thead>
					<tbody>
						{this.state.project.map((project)=>{
							return(
							<tr>
								<td>
									{project.date}
								</td>
								<td>
								&#8377;  {project.invested_amount}
								</td>
								<td>
								{project.partake}
								</td>
						</tr>
						)
						})}
					</tbody>
					</Table> */}
					
			    </div>
			  </div>
			</div>
        }
}

export default withRouter(Funding)