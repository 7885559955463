import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class CallToAction extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="cta-area bg-green pd-top-118 go-top pd-bottom-120 text-center" >
		  <div className=" pd-top-118 pd-bottom-120"
		//   style={{background:"#0d2b5c"}}
		  >
		    <div className="row justify-content-center">
		      <div className="col-xl-12 col-lg-12">
		        <div className="section-title style-white mb-0">
		          <h2 style={{color:"white"}}>Discover Private Business Opportunities<br/>
					from your Localities with<br/>
						Unmatched Returns 
					</h2>
		          {/* <p>consecte Lorem ipsum dolor sit amet, Lorem ipsum dolor  amet, consecte Lorem ipsum dolor sit adipisicing amet, consectetur  sed do eiusmod tempor incididunt dolore magna consecrem adipisicing ipsum dolor sit amet, consectetur elit,</p> */}
		        </div>
		        <div className="btn-wrap pd-top-118 mt-4 pt-3">
					<br/>
					<br/>
					<h2>
		          <Link  style={{background:"#0d2b5c", color:"white", padding:'15px', borderRadius:'30px'}} to="/sign-in">Invest with Confidence </Link>
				  </h2>
		          {/* <Link className="btn btn-base ml-2" to="/property-details">BUY NOW</Link> */}
		        </div>
		      </div>
		    </div>
		  </div>
		</div>

        }
}

export default CallToAction