import React from 'react';
import Navbar from './global-components/navbar-v3';
import PageHeader from './global-components/page-header';
import KycAdd from './section-components/kyc-add';
import Footer from './global-components/footer';

const SignInPage = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Complete Kyc"  />
        <KycAdd />
        <Footer />
    </div>
}

export default SignInPage

