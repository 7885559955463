import React, { Component } from 'react';
import { Link, withRouter} from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import parse from 'html-react-parser';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { serviceUrl } from '../../constant';

const validatePassword = (value) => {
	let error;
	const regularExpression = new RegExp(
		/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.+*{\]{[\-,;`<>':"=^#_|/\\])(?=.*?[A-Za-z\d@$!%*+?&.+*{\]{[\-,;`<>':"=^#_|/\\]).{8,}/
	  );
	if (!value) {
	  error = 'Please enter your password';
	} else if (!regularExpression.test(value)) {
	  error = 'Password must have minimum 8 characters, must contain 1 uppercase, 1 lowercase, 1 number and 1 special character';
	}
	return error;
  };
  const validateNewPassword = (values) => {
	console.log("hi");
	const { new_password, confPassword } = values;
	const errors = {};
	if (confPassword && new_password !== confPassword) {
	  errors.confPassword =
		'New password and Confirm password are not matching';
	}
	return errors;
  };
class SignUp extends Component {
	state={
		new_password:"",
		confPassword:"",
	}

	componentDidMount() {

     const $ = window.$;

     $('body').addClass( 'bg-gray' );

 	}
	 onSubmit= async(values)=>{
		// e.preventDefault();
		if ( values.password !== '' ) {
			console.log("value",values);
			try {
				let project2 = await axios.post(`${serviceUrl}investor/resetpassword/${this.props.match.params.token}`, values);
				// const project = project2.data.data.items[0].projects;
				//   this.setState ({project});
				//   console.log(project2);
				  if(project2.status===200){
					// NotificationManager.success('Registration successful', `Please login!!`);
					// alert("registration successful, please login again!!!");
					toast.success(project2.data.data.description, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "light",
						});
					this.props.history.push('/sign-in');
					// console.log("sucess");
				}
			
				// Work with the response...
			} catch (err) {
				if (err.response) {
					// The client was given an error response (5xx, 4xx)
					console.log(err.response.data);
					// NotificationManager.error( err.response.data.message, 'Error message', 5000);
					if(err.response.data.errors?.length > 0 ){
						toast.error(err.response.data.errors[0].message, {
							position: "top-center",
							autoClose: 8000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: "light",
							});
						}
						if(err.response.status === 403){
						toast.error(err.response.data.data.kind, {
							position: "top-center",
							autoClose: 8000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: "light",
							});	
					}
						if(err.response.data.message){
							toast.error(err.response.data.message, {
								position: "top-center",
								autoClose: 8000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
								theme: "light",
								});
						}
				} else if (err.request) {
					// The client never received a response, and the request was never left
					console.log(err.request);
				} else {
					// Anything else
					console.log('Error', err.message);
				}
			}
		}
	}

    render() {

        // let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="signup-page-area pd-top-100">
			  <div className="container">
			    <div className="row justify-content-center">
			      <div className="col-xl-6 col-lg-7">
			        {/* <form className="signin-inner"> */}
					<Formik  
					validate={validateNewPassword}
					 initialValues={{
					new_password: '',
					confPassword: '',
					}}
					onSubmit={(values)=>this.onSubmit(values)}>
              {({ errors, touched }) => (
                <Form className="signin-inner">
			          <div className="row">
			            <div className="col-12">
			              <label className="single-input-inner style-bg-border">New Password
						  <Field
                      type={this.state.type ? "text": "password"}
                      name="new_password"
                      validate={validatePassword}
                    />
                    {errors.new_password && touched.new_password && (
                      <div className='warn'>
                        {errors.new_password}
                      </div>
                    )}
							<button onClick={(e)=>(e.preventDefault(),this.setState(prevState=>({type: !prevState.type})))}>show password</button>
			              </label>
			            </div>
						<div className="col-12">
			              <label className="single-input-inner style-bg-border">Confirm Password
						  <Field
                      type={this.state.type ? "text": "password"}
                      name="confPassword"
                      validate={validatePassword}
                    />
                    {errors.confPassword && touched.confPassword && (
                      <div className='warn'>
                        {errors.confPassword}
                      </div>
                    )}
							{/* <button onClick={(e)=>(e.preventDefault(),this.setState(prevState=>({type: !prevState.type})))}>show password</button> */}
			              </label>
			            </div>
			            {/* <div className="col-12">
			              <label className="single-input-inner style-bg-border">
			                <input type="text" placeholder="Confirm Password" />
			              </label>
			            </div> */}
			            <div className="col-12 mb-4">
			              <button className="btn btn-base w-100">Change Password </button>
			            </div>
			            <div className="col-12">
			              <span>Already a member?  </span>
			              <Link to="/sign-in"><strong> Sign In</strong></Link>
			            </div>
			          </div>
			        {/* </form> */}
					</Form>
              )}
            </Formik>
			      </div>
			    </div>
				{/* <ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
				/> */}
			  </div>
			</div>

        }
}

export default withRouter(SignUp)