import { iName, serviceUrl, tokenName } from './constant';
import jwtDecode from 'jwt-decode';
import axios from 'axios';

export const getName = ()=>{
    const Name = localStorage.getItem(iName);
    let token = localStorage.getItem(tokenName)
        if(token){
            let tokenExpiration = jwtDecode(token).exp;
            let dateNow = new Date();

            if(tokenExpiration < dateNow.getTime()/1000){
                localStorage.clear();
                return false; 
            }else{
               return Name;
            }
        }
}
export const getToken = ()=>{
    let token = localStorage.getItem(tokenName)
        if(token){
            let tokenExpiration = jwtDecode(token).exp;
            let dateNow = new Date();

            if(tokenExpiration < dateNow.getTime()/1000){
                localStorage.clear();
                return false; 
            }else{
               return (axios.defaults.headers.common[
                "x-id-token"
            ] = token);
            }
        }
}
