import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import axios from 'axios';
import { Image } from 'cloudinary-react';
import { serviceUrl } from '../../constant';

class Property extends Component {
	constructor(props) {
        super(props);
	this.state = {
		project: []
	  };
	 this.ideationFetch = this.ideationFetch.bind(this);
	//  this.fundingFetch = this.fundingFetch.bind(this);
	}
	 async ideationFetch(){
		this.setState({project:[]});
		try {
			let project2 = await axios.get(`${serviceUrl}prj/ideation/list?limit=10&page_no=1`);
			const project = project2.data.data.items[0].upgrades;
		  	this.setState ({project});
		  	console.log(project);
		
			// Work with the response...
		} catch (err) {
			if (err.response) {
				// The client was given an error response (5xx, 4xx)
				console.log(err.response.data);
       			console.log(err.response.status);
        		console.log(err.response.headers);
			} else if (err.request) {
				// The client never received a response, and the request was never left
				console.log(err.request);
			} else {
				// Anything else
				console.log('Error', err.message);
			}
		}
	  }
	//   async fundingFetch(){
	// 	this.setState({project:[]});
	// 	try {
	// 		let project2 = await axios.get(`${serviceUrl}prj/funding/list?limit=10&page_no=1`);
	// 		const project = project2.data.data.items[0].upgrades;
			
	// 	  	this.setState ({project});
	// 	  	console.log(project);
		
	// 		// Work with the response...
	// 	} catch (err) {
	// 		if (err.response) {
	// 			// The client was given an error response (5xx, 4xx)
	// 			console.log(err.response.data);
    //    			console.log(err.response.status);
    //     		console.log(err.response.headers);
	// 		} else if (err.request) {
	// 			// The client never received a response, and the request was never left
	// 			console.log(err.request);
	// 		} else {
	// 			// Anything else
	// 			console.log('Error', err.message);
	// 		}
	// 	}
	//   }
	  async componentDidMount() {
		this.ideationFetch();
		  
	  }

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="propartes-area pd-top-118 pd-bottom-90 ">
			  <div className="container">
			    {/* <div className="section-title text-center">
			      <h6>Projects</h6>
			      <h2>New Projects </h2>
			    </div> */}
			    <div className="mgd-tab-inner text-center"> 
				<button className="btn btn-base w-10" style={{color:"white", background:"#218838"}} >Ideation Phase</button>
				<Link to="/f/project">
			          <button className="btn btn-base w-10" style={{color:"Black", background:"#ececec"}} >Funding Phase</button>
				</Link>
				
			    </div>
			    <div className="tab-content " id="myTabContent">
			      <div className="tab-pane fade show active" id="rent1" role="tabpanel" aria-labelledby="rent1-tab">
			        {/* <div className="row go-top">
			          <div className="col-lg-4 col-md-6">
			            <div className="single-product-wrap style-bottom">
			              <div className="thumb">
			                <img src={publicUrl+"assets/img/project/1.png"} alt="img" />
			                <div className="product-wrap-details">
			                  <div className="media">
			                    <div className="author">
			                      <img src={publicUrl+"assets/img/author/1.png"} alt="img" />           
			                    </div>
			                    <div className="media-body">
			                      <h6><Link to="/property">Owner Name</Link></h6>
			                      <p><img src={publicUrl+"assets/img/icon/location-alt.png"} alt="img" />New York real estate </p>
			                    </div>
			                    <a className="fav-btn float-right" href="#"><i className="far fa-heart" /></a>
			                  </div>
			                </div> 
			              </div> 
			              <div className="product-details-inner">
			                <h4><Link to="/property-details">Daily Apartment</Link></h4>
			                <ul className="meta-inner">
			                  <li><img src={publicUrl+"assets/img/icon/location2.png"} alt="img" />New York</li>
			                  <li><Link to="/property">For Sell</Link></li>
			                </ul>
			                <p>Lorem ipsum dolor consectetur iicing elit, sed do eius Lorem ipsum dolo amet, costur adipisicing eiusmod.</p>
			              </div>
			              <div className="product-meta-bottom">
			                <span className="price">$ 80,650.00</span>
			                <span>For sale</span>
			                <span>1 year ago</span>
			              </div>         
			            </div>
			          </div>
			          <div className="col-lg-4 col-md-6">
			            <div className="single-product-wrap style-bottom">
			              <div className="thumb">
			                <img src={publicUrl+"assets/img/project/2.png" }alt="img" />
			                <div className="product-wrap-details">
			                  <div className="media">
			                    <div className="author">
			                      <img src={publicUrl+"assets/img/author/2.png"} alt="img" />           
			                    </div>
			                    <div className="media-body">
			                      <h6><Link to="/property">Owner Name</Link></h6>
			                      <p><img src={publicUrl+"assets/img/icon/location-alt.png"} alt="img" />New York real estate </p>
			                    </div>
			                    <a className="fav-btn float-right" href="#"><i className="far fa-heart" /></a>
			                  </div>
			                </div> 
			              </div> 
			              <div className="product-details-inner">
			                <h4><Link to="/property-details">Gorgeous Villa</Link></h4>
			                <ul className="meta-inner">
			                  <li><img src={publicUrl+"assets/img/icon/location2.png"} alt="img" />New York</li>
			                  <li><Link to="/property">For Sell</Link></li>
			                </ul>
			                <p>Lorem ipsum dolor consectetur iicing elit, sed do eius Lorem ipsum dolo amet, costur adipisicing eiusmod.</p>
			              </div>
			              <div className="product-meta-bottom">
			                <span className="price">$ 80,650.00</span>
			                <span>For sale</span>
			                <span>1 year ago</span>
			              </div>         
			            </div>
			          </div>
			          <div className="col-lg-4 col-md-6">
			            <div className="single-product-wrap style-bottom">
			              <div className="thumb">
			                <img src={publicUrl+"assets/img/project/3.png"} alt="img" />
			                <div className="product-wrap-details">
			                  <div className="media">
			                    <div className="author">
			                      <img src={publicUrl+"assets/img/author/3.png"} alt="img" />           
			                    </div>
			                    <div className="media-body">
			                      <h6><Link to="/property">Owner Name</Link></h6>
			                      <p><img src={publicUrl+"assets/img/icon/location-alt.png"} alt="img" />New York real estate </p>
			                    </div>
			                    <a className="fav-btn float-right" href="#"><i className="far fa-heart" /></a>
			                  </div>
			                </div> 
			              </div> 
			              <div className="product-details-inner">
			                <h4><Link to="/property-details">Daily Property</Link></h4>
			                <ul className="meta-inner">
			                  <li><img src={publicUrl+"assets/img/icon/location2.png"} alt="img" />New York</li>
			                  <li><Link to="/property">For Sell</Link></li>
			                </ul>
			                <p>Lorem ipsum dolor consectetur iicing elit, sed do eius Lorem ipsum dolo amet, costur adipisicing eiusmod.</p>
			              </div>
			              <div className="product-meta-bottom">
			                <span className="price">$ 80,650.00</span>
			                <span>For sale</span>
			                <span>1 year ago</span>
			              </div>         
			            </div>
			          </div>
			          <div className="col-lg-4 col-md-6">
			            <div className="single-product-wrap style-bottom">
			              <div className="thumb">
			                <img src={publicUrl+"assets/img/project/4.png"} alt="img" />
			                <div className="product-wrap-details">
			                  <div className="media">
			                    <div className="author">
			                      <img src={publicUrl+"assets/img/author/4.png"} alt="img" />           
			                    </div>
			                    <div className="media-body">
			                      <h6><Link to="/property">Owner Name</Link></h6>
			                      <p><img src={publicUrl+"assets/img/icon/location-alt.png"} alt="img" />New York real estate </p>
			                    </div>
			                    <a className="fav-btn float-right" href="#"><i className="far fa-heart" /></a>
			                  </div>
			                </div> 
			              </div> 
			              <div className="product-details-inner">
			                <h4><Link to="/property-details">Farm House</Link></h4>
			                <ul className="meta-inner">
			                  <li><img src={publicUrl+"assets/img/icon/location2.png" }alt="img" />New York</li>
			                  <li><Link to="/property">For Sell</Link></li>
			                </ul>
			                <p>Lorem ipsum dolor consectetur iicing elit, sed do eius Lorem ipsum dolo amet, costur adipisicing eiusmod.</p>
			              </div>
			              <div className="product-meta-bottom">
			                <span className="price">$ 80,650.00</span>
			                <span>For sale</span>
			                <span>1 year ago</span>
			              </div>         
			            </div>
			          </div>
			          <div className="col-lg-4 col-md-6">
			            <div className="single-product-wrap style-bottom">
			              <div className="thumb">
			                <img src={publicUrl+"assets/img/project/5.png"} alt="img" />
			                <div className="product-wrap-details">
			                  <div className="media">
			                    <div className="author">
			                      <img src={publicUrl+"assets/img/author/5.png"} alt="img" />           
			                    </div>
			                    <div className="media-body">
			                      <h6><Link to="/property">Owner Name</Link></h6>
			                      <p><img src={publicUrl+"assets/img/icon/location-alt.png"} alt="img" />New York real estate </p>
			                    </div>
			                    <a className="fav-btn float-right" href="#"><i className="far fa-heart" /></a>
			                  </div>
			                </div> 
			              </div> 
			              <div className="product-details-inner">
			                <h4><Link to="/property-details">Beach Side House</Link></h4>
			                <ul className="meta-inner">
			                  <li><img src={publicUrl+"assets/img/icon/location2.png"} alt="img" />New York</li>
			                  <li><Link to="/property">For Sell</Link></li>
			                </ul>
			                <p>Lorem ipsum dolor consectetur iicing elit, sed do eius Lorem ipsum dolo amet, costur adipisicing eiusmod.</p>
			              </div>
			              <div className="product-meta-bottom">
			                <span className="price">$ 80,650.00</span>
			                <span>For sale</span>
			                <span>1 year ago</span>
			              </div>         
			            </div>
			          </div>
			          <div className="col-lg-4 col-md-6">
			            <div className="single-product-wrap style-bottom">
			              <div className="thumb">
			                <img src={publicUrl+"assets/img/project/6.png"} alt="img" />
			                <div className="product-wrap-details">
			                  <div className="media">
			                    <div className="author">
			                      <img src={publicUrl+"assets/img/author/6.png"} alt="img" />           
			                    </div>
			                    <div className="media-body">
			                      <h6><Link to="/property">Owner Name</Link></h6>
			                      <p><img src={publicUrl+"assets/img/icon/location-alt.png"} alt="img" />New York real estate </p>
			                    </div>
			                    <a className="fav-btn float-right" href="#"><i className="far fa-heart" /></a>
			                  </div>
			                </div> 
			              </div> 
			              <div className="product-details-inner">
			                <h4><Link to="/property-details">Gorgeous Hotel</Link></h4>
			                <ul className="meta-inner">
			                  <li><img src={publicUrl+"assets/img/icon/location2.png"} alt="img" />New York</li>
			                  <li><Link to="/property">For Sell</Link></li>
			                </ul>
			                <p>Lorem ipsum dolor consectetur iicing elit, sed do eius Lorem ipsum dolo amet, costur adipisicing eiusmod.</p>
			              </div>
			              <div className="product-meta-bottom">
			                <span className="price">$ 80,650.00</span>
			                <span>For sale</span>
			                <span>1 year ago</span>
			              </div>         
			            </div>
			          </div>
			        </div> */}
					<div className="row ">
					{this.state.project.length && 
					this.state.project.map((item)=> (
						<div className="col-lg-4 col-md-6">
			            <div className="single-product-wrap style-bottom">
			              <div className="thumb">
			                {/* <img src={publicUrl+"assets/img/project/1.png"} alt="img" /> */}
							{item.img1 && (
								<Image
									cloudName="dcjvlrsp9"
									publicId={`launchaventure/project/${item.img1}`}
								/>
								)}
			                <div className="product-wrap-details">
			                  <div className="media">
			                    <div className="author">
			                      {/* <img src={publicUrl+"assets/img/author/1.png"} alt="img" />            */}
			                    </div>
			                    <div className="media-body">
			                      {/* <h6><Link to="/property">Owner Name</Link></h6> */}
			                      <p><img src={publicUrl+"assets/img/icon/location-alt.png"} alt="img" />{item.city}</p>
			                    </div>
			                    {/* <a className="fav-btn float-right" href="#"><i className="far fa-heart" /></a> */}
			                  </div>
			                </div> 
			              </div> 
			              <div className="product-details-inner">
			                <h4><Link to={`/l/project/${item.id}`}>{item.title}</Link></h4>
			                <ul className="meta-inner">
			                  <li><img src={publicUrl+"assets/img/icon/location2.png"} alt="img" />{item.city}</li>
			                  <li><Link to={`/l/project/${item.id}`}>Details</Link></li>
			                </ul>
			                <p dangerouslySetInnerHTML={{
                    __html: item.shot_desc.substring(0, 75),
                  }}/>
			              </div>
			              <div className="product-meta-bottom">
			                <span className="price">&#8377; {item.total_fund_required}</span>
			                <span> Active Till</span>
			                <span>{item.completion_time} days</span>
			              </div>         
			            </div>
			          </div>
					))}
					</div>
			      </div>
			      {/* <div className="tab-pane fade" id="sell1" role="tabpanel" aria-labelledby="sell1-tab">
			        <div className="row">
			          <div className="col-lg-4 col-md-6">
			            <div className="single-product-wrap style-bottom">
			              <div className="thumb">
			                <img src={publicUrl+"assets/img/project/4.png"} alt="img" />
			                <div className="product-wrap-details">
			                  <div className="media">
			                    <div className="author">
			                      <img src={publicUrl+"assets/img/author/4.png"} alt="img" />           
			                    </div>
			                    <div className="media-body">
			                      <h6><Link to="/property">Owner Name</Link></h6>
			                      <p><img src={publicUrl+"assets/img/icon/location-alt.png"} alt="img" />New York real estate </p>
			                    </div>
			                    <a className="fav-btn float-right" href="#"><i className="far fa-heart" /></a>
			                  </div>
			                </div> 
			              </div> 
			              <div className="product-details-inner">
			                <h4><Link to="/property-details">Farm House</Link></h4>
			                <ul className="meta-inner">
			                  <li><img src={publicUrl+"assets/img/icon/location2.png"} alt="img" />New York</li>
			                  <li><Link to="/property">For Sell</Link></li>
			                </ul>
			                <p>Lorem ipsum dolor consectetur iicing elit, sed do eius Lorem ipsum dolo amet, costur adipisicing eiusmod.</p>
			              </div>
			              <div className="product-meta-bottom">
			                <span className="price">$ 80,650.00</span>
			                <span>For sale</span>
			                <span>1 year ago</span>
			              </div>         
			            </div>
			          </div>
			          <div className="col-lg-4 col-md-6">
			            <div className="single-product-wrap style-bottom">
			              <div className="thumb">
			                <img src={publicUrl+"assets/img/project/5.png"} alt="img" />
			                <div className="product-wrap-details">
			                  <div className="media">
			                    <div className="author">
			                      <img src={publicUrl+"assets/img/author/5.png"} alt="img" />           
			                    </div>
			                    <div className="media-body">
			                      <h6><Link to="/property">Owner Name</Link></h6>
			                      <p><img src={publicUrl+"assets/img/icon/location-alt.png"} alt="img" />New York real estate </p>
			                    </div>
			                    <a className="fav-btn float-right" href="#"><i className="far fa-heart" /></a>
			                  </div>
			                </div> 
			              </div> 
			              <div className="product-details-inner">
			                <h4><Link to="/property-details">Beach Side House</Link></h4>
			                <ul className="meta-inner">
			                  <li><img src={publicUrl+"assets/img/icon/location2.png"} alt="img" />New York</li>
			                  <li><Link to="/property">For Sell</Link></li>
			                </ul>
			                <p>Lorem ipsum dolor consectetur iicing elit, sed do eius Lorem ipsum dolo amet, costur adipisicing eiusmod.</p>
			              </div>
			              <div className="product-meta-bottom">
			                <span className="price">$ 80,650.00</span>
			                <span>For sale</span>
			                <span>1 year ago</span>
			              </div>         
			            </div>
			          </div>
			          <div className="col-lg-4 col-md-6">
			            <div className="single-product-wrap style-bottom">
			              <div className="thumb">
			                <img src={publicUrl+"assets/img/project/6.png"} alt="img" />
			                <div className="product-wrap-details">
			                  <div className="media">
			                    <div className="author">
			                      <img src={publicUrl+"assets/img/author/6.png"} alt="img" />           
			                    </div>
			                    <div className="media-body">
			                      <h6><Link to="/property">Owner Name</Link></h6>
			                      <p><img src={publicUrl+"assets/img/icon/location-alt.png"} alt="img" />New York real estate </p>
			                    </div>
			                    <a className="fav-btn float-right" href="#"><i className="far fa-heart" /></a>
			                  </div>
			                </div> 
			              </div> 
			              <div className="product-details-inner">
			                <h4><Link to="/property-details">Gorgeous Hotel</Link></h4>
			                <ul className="meta-inner">
			                  <li><img src={publicUrl+"assets/img/icon/location2.png"} alt="img" />New York</li>
			                  <li><Link to="/property">For Sell</Link></li>
			                </ul>
			                <p>Lorem ipsum dolor consectetur iicing elit, sed do eius Lorem ipsum dolo amet, costur adipisicing eiusmod.</p>
			              </div>
			              <div className="product-meta-bottom">
			                <span className="price">$ 80,650.00</span>
			                <span>For sale</span>
			                <span>1 year ago</span>
			              </div>         
			            </div>
			          </div>
			          <div className="col-lg-4 col-md-6">
			            <div className="single-product-wrap style-bottom">
			              <div className="thumb">
			                <img src={publicUrl+"assets/img/project/1.png"} alt="img" />
			                <div className="product-wrap-details">
			                  <div className="media">
			                    <div className="author">
			                      <img src={publicUrl+"assets/img/author/1.png"} alt="img" />           
			                    </div>
			                    <div className="media-body">
			                      <h6><Link to="/property">Owner Name</Link></h6>
			                      <p><img src={publicUrl+"assets/img/icon/location-alt.png"} alt="img" />New York real estate </p>
			                    </div>
			                    <a className="fav-btn float-right" href="#"><i className="far fa-heart" /></a>
			                  </div>
			                </div> 
			              </div> 
			              <div className="product-details-inner">
			                <h4><Link to="/property-details">Daily Apartment</Link></h4>
			                <ul className="meta-inner">
			                  <li><img src={publicUrl+"assets/img/icon/location2.png"} alt="img" />New York</li>
			                  <li><Link to="/property">For Sell</Link></li>
			                </ul>
			                <p>Lorem ipsum dolor consectetur iicing elit, sed do eius Lorem ipsum dolo amet, costur adipisicing eiusmod.</p>
			              </div>
			              <div className="product-meta-bottom">
			                <span className="price">$ 80,650.00</span>
			                <span>For sale</span>
			                <span>1 year ago</span>
			              </div>         
			            </div>
			          </div>
			          <div className="col-lg-4 col-md-6">
			            <div className="single-product-wrap style-bottom">
			              <div className="thumb">
			                <img src={publicUrl+"assets/img/project/2.png"} alt="img" />
			                <div className="product-wrap-details">
			                  <div className="media">
			                    <div className="author">
			                      <img src={publicUrl+"assets/img/author/2.png"} alt="img" />           
			                    </div>
			                    <div className="media-body">
			                      <h6><Link to="/property">Owner Name</Link></h6>
			                      <p><img src={publicUrl+"assets/img/icon/location-alt.png"} alt="img" />New York real estate </p>
			                    </div>
			                    <a className="fav-btn float-right" href="#"><i className="far fa-heart" /></a>
			                  </div>
			                </div> 
			              </div> 
			              <div className="product-details-inner">
			                <h4><Link to="/property-details">Gorgeous Villa</Link></h4>
			                <ul className="meta-inner">
			                  <li><img src={publicUrl+"assets/img/icon/location2.png"} alt="img" />New York</li>
			                  <li><Link to="/property">For Sell</Link></li>
			                </ul>
			                <p>Lorem ipsum dolor consectetur iicing elit, sed do eius Lorem ipsum dolo amet, costur adipisicing eiusmod.</p>
			              </div>
			              <div className="product-meta-bottom">
			                <span className="price">$ 80,650.00</span>
			                <span>For sale</span>
			                <span>1 year ago</span>
			              </div>         
			            </div>
			          </div>
			          <div className="col-lg-4 col-md-6">
			            <div className="single-product-wrap style-bottom">
			              <div className="thumb">
			                <img src={publicUrl+"assets/img/project/3.png"} alt="img" />
			                <div className="product-wrap-details">
			                  <div className="media">
			                    <div className="author">
			                      <img src={publicUrl+"assets/img/author/3.png"} alt="img" />           
			                    </div>
			                    <div className="media-body">
			                      <h6><Link to="/property">Owner Name</Link></h6>
			                      <p><img src={publicUrl+"assets/img/icon/location-alt.png"} alt="img" />New York real estate </p>
			                    </div>
			                    <a className="fav-btn float-right" href="#"><i className="far fa-heart" /></a>
			                  </div>
			                </div> 
			              </div> 
			              <div className="product-details-inner">
			                <h4><Link to="/property-details">Daily Property</Link></h4>
			                <ul className="meta-inner">
			                  <li><img src={publicUrl+"assets/img/icon/location2.png"} alt="img" />New York</li>
			                  <li><Link to="/property">For Sell</Link></li>
			                </ul>
			                <p>Lorem ipsum dolor consectetur iicing elit, sed do eius Lorem ipsum dolo amet, costur adipisicing eiusmod.</p>
			              </div>
			              <div className="product-meta-bottom">
			                <span className="price">$ 80,650.00</span>
			                <span>For sale</span>
			                <span>1 year ago</span>
			              </div>         
			            </div>
			          </div>
			        </div>
			      </div> */}
			    </div>
			  </div>
			</div>

        }
}

export default Property