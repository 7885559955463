import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Link, withRouter } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import YouTube from 'react-youtube';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Image } from 'cloudinary-react';
import { serviceUrl } from '../../constant';
import { getToken } from '../../globalfunction';
class Blog extends Component {
  constructor(props) {
		super(props);
	this.state={
		item:{},
		modal: false,
		check:"",
    fund:"",
	}
	this.toggle = this.toggle.bind(this);
	}
	toggle() {
		this.setState({
		  modal: !this.state.modal
		});
	  }
	  async onInvest(){
		console.log("value",this.state.fund);
    // toast("Hello", { autoClose: false });
    const fund = this.state.fund;
    getToken();
    try {
      // console.log(this.props.match.params.id);
      let project2 = await axios.post(`${serviceUrl}prj/request/${this.props.match.params.id}`,{fund:this.state.fund});
      // const item = project2.data.data.items[0].upgrades[0];
        // this.setState ({item});
        // this.setState({fund:project2.data.data.items[0].upgrades[0].predefined_amt})
        console.log("prj2",project2);
        if(project2.status===200){
          toast.success(`${this.state.fund} Rs investment request`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "light",
						});
            this.fetchProjectDetail();
        }
    
      // Work with the response...
    } catch (err) {
      if (err.response) {
        // The client was given an error response (5xx, 4xx)
        console.log(err.response.data);
        // toast.error(err.response.data.message, {
        //   position: "top-center",
        //   autoClose: 8000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        //   });
          toast.error(err.response.data.errors[0].message, {
            position: "top-center",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
           console.log(err.response.status);
        console.log(err.response.headers);
      } else if (err.request) {
        // The client never received a response, and the request was never left
        console.log(err.request);
      } else {
        // Anything else
        console.log('Error', err.message);
      }
    }
	  }
    async fetchProjectDetail(){
  try {
    // console.log(this.props.match.params.id);
    let project2 = await axios.get(`${serviceUrl}prj/details/${this.props.match.params.id}`);
    console.log(project2);
    const item = project2.data.data.items[0].upgrades[0];
      this.setState ({item});
      toast.success(`Project Detail retrived`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
      this.setState({fund:project2.data.data.items[0].upgrades[0].predefined_amt})
      // console.log(this.state.item);
  
    // Work with the response...
  }  catch  (err) {
    if (err.response) {
      // The client was given an error response (5xx, 4xx)
      console.log(err.response.data);
      toast.error(err.response.data.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
      // NotificationManager.error( err.response.data.message, 'Error message', 5000)
       

         console.log(err.response.status);
      console.log(err.response.headers);
    } else if (err.request) {
      // The client never received a response, and the request was never left
      console.log(err.request);
    } else {
      // Anything else
      console.log('Error', err.message);
    }
  }
}
 componentDidMount() {
  // toast.error("Hello", { autoClose: false });
     const $ = window.$;

     $( 'body' ).addClass( 'body-bg' );
     this.fetchProjectDetail();

 }

  render() {
    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'
    return (
		<div className="blog-page-area pd-top-120 ">
      {/* <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      /> */}
		  <div className="container">
        <div className="row">
        <div className="col-lg-12">
        <div className="single-blog-inner text-center">
        <h4>{this.state.item.title}</h4>
              <h6>City: {this.state.item.city}</h6>
              <p dangerouslySetInnerHTML={{
                    __html: this.state.item.shot_desc,
                  }}/>
        </div>
        </div>
        </div>
        <div className="row">
        <div className="col-lg-8">
		        <div className="single-blog-inner">
		          <div className="thumb">
		            {/* <img src={publicUrl+"assets/img/blog/1.png"} alt="img" /> */}
                {this.state.item.videoId !=0 && (
								<YouTube videoId={this.state.item.videoId}/>
								)|| this.state.item.main_img !=0 &&(<Image
									cloudName="dcjvlrsp9"
									publicId={`launchaventure/project/${this.state.item.main_img}`}
								/>)}
		          </div>
              <br/>
               <h4> {this.state.item.title}</h4>
              {/*<h6>City: {this.state.item.city}</h6>
              <p dangerouslySetInnerHTML={{
                    __html: this.state.item.shot_desc,
                  }}/> */}
            </div>
        </div>
        {!this.state.item.state &&<div className="col-lg-4">
            <aside className="sidebar-area">
              <div className="widget widget-author text-center">
                {/* <h4 className="widget-title">About Me</h4>  */}
                <div className="details">
                  <h3>&#8377; {this.state.item.invested}</h3>
                  <p>Interested Investement out of
                  <h5>&#8377; {this.state.item.total_fund_required}</h5></p>
                  {/* <br/> */}
                  <h4>{this.state.item.invester} Investors</h4>
                  {/* <br/> */}
               <h4>How Much You Want Invest</h4>   
              {/* <Form> */}
							{/* <FormGroup check inline>
							<Label check>
								<Input type="checkbox"  checked={this.state.check === "1000"}  onChange={()=>(this.setState({check:"1000"}))} /> 1000
							</Label>
							</FormGroup>
							<FormGroup check inline>
							<Label check>
								<Input type="checkbox" checked={this.state.check === "2000"} onChange={()=>(this.setState({check:"2000"}))}/> 2000
							</Label>
							</FormGroup>
              <FormGroup check inline>
							<Label check>
								<Input type="checkbox" checked={this.state.check === "3000"} onChange={()=>(this.setState({check:"3000"}))}/> 3000
							</Label>
							</FormGroup>
              <FormGroup check inline>
							<Label check>
								<Input type="checkbox" checked={this.state.check === "4000"} onChange={()=>(this.setState({check:"4000"}))}/> 4000
							</Label>
							</FormGroup>
              <FormGroup check inline>
							<Label check>
								<Input type="checkbox" checked={this.state.check === "5000"} onChange={()=>(this.setState({check:"5000"}))}/> 5000
							</Label>
							</FormGroup>
              <FormGroup check inline>
							<Label check>
								<Input type="checkbox" checked={this.state.check === "6000"} onChange={()=>(this.setState({check:"6000"}))}/> 6000
							</Label>
							</FormGroup> */}
              {/* <FormGroup> */}
              {/* <br/> */}
              <input type="text" name="fund" value={this.state.fund} onChange={(e)=> {return(e.preventDefault(),this.setState({fund:e.target.value}))}}/> 
              {/* </FormGroup> */}
						{/* </Form> */}<br/><br/>
            <Button color="success" onClick={()=>this.onInvest()}>Show Interest</Button>
                  
                </div>        
              </div>
            </aside>
          </div> } 
          {this.state.item.state &&<div className="col-lg-4">
            <aside className="sidebar-area">
              <div className="widget widget-author text-center">
                {/* <h4 className="widget-title">About Me</h4>  */}
                <div className="details">
                  <h3>&#8377; {this.state.item.invested}</h3>
                  <p>Interested Investement out of
                  <h5>&#8377; {this.state.item.total_fund_required}</h5></p>
                  {/* <br/> */}
                  <h4>{this.state.item.invester} Investors</h4>
                  {/* <br/> */}
               <h4>How Much You Want Invest</h4>   
              {/* <Form> */}
							{/* <FormGroup check inline>
							<Label check>
								<Input type="checkbox"  checked={this.state.check === "1000"}  onChange={()=>(this.setState({check:"1000"}))} /> 1000
							</Label>
							</FormGroup>
							<FormGroup check inline>
							<Label check>
								<Input type="checkbox" checked={this.state.check === "2000"} onChange={()=>(this.setState({check:"2000"}))}/> 2000
							</Label>
							</FormGroup>
              <FormGroup check inline>
							<Label check>
								<Input type="checkbox" checked={this.state.check === "3000"} onChange={()=>(this.setState({check:"3000"}))}/> 3000
							</Label>
							</FormGroup>
              <FormGroup check inline>
							<Label check>
								<Input type="checkbox" checked={this.state.check === "4000"} onChange={()=>(this.setState({check:"4000"}))}/> 4000
							</Label>
							</FormGroup>
              <FormGroup check inline>
							<Label check>
								<Input type="checkbox" checked={this.state.check === "5000"} onChange={()=>(this.setState({check:"5000"}))}/> 5000
							</Label>
							</FormGroup>
              <FormGroup check inline>
							<Label check>
								<Input type="checkbox" checked={this.state.check === "6000"} onChange={()=>(this.setState({check:"6000"}))}/> 6000
							</Label>
							</FormGroup> */}
              {/* <FormGroup> */}
              {/* <br/> */}
              <input type="text" name="fund" value={this.state.fund} onChange={(e)=> {return(e.preventDefault(),this.setState({fund:e.target.value}))}}/> 
              {/* </FormGroup> */}
						{/* </Form> */}<br/><br/>
            <Button color="success" onClick={()=>this.onInvest()}>Show Interest</Button>
                  
                </div>        
              </div>
            </aside>
          </div> }      
        </div>
		    <div className="row">
		      <div className="col-lg-8">
		        <div className="single-blog-inner">
		          {/* <div className="thumb">
              {this.state.item.img1 && (
								<Image
									cloudName="dcjvlrsp9"
									publicId={`launchaventure/project/${this.state.item.img1}`}
								/>
								)}
		          </div>  */}
		          <div className="details">
		            {/* <div className="cat"><Link to="/blog-details">Business</Link></div> */}
		            
		            <p dangerouslySetInnerHTML={{
                    __html: this.state.item.desc1,
                  }}/>
		            <div className="row">
		              
		            </div>
		          </div>
              <div className="thumb">
              {this.state.item.img2 && (
								<Image
									cloudName="dcjvlrsp9"
									publicId={`launchaventure/project/${this.state.item.img2}`}
								/>
								)}
		          </div> 
		          <div className="details">
		            {/* <div className="cat"><Link to="/blog-details">Business</Link></div> */}
		            <p dangerouslySetInnerHTML={{
                    __html: this.state.item.desc2,
                  }}/>
		            <div className="row">
		              
		            </div>
		          </div>
              <div className="thumb">
              {this.state.item.img3 && (
								<Image
									cloudName="dcjvlrsp9"
									publicId={`launchaventure/project/${this.state.item.img3}`}
								/>
								)}
		          </div> 
		          <div className="details">
		            {/* <div className="cat"><Link to="/blog-details">Business</Link></div> */}
		            <p dangerouslySetInnerHTML={{
                    __html: this.state.item.desc3,
                  }}/>
		            <div className="row">
		              
		            </div>
		          </div>
              <div className="thumb">
              {this.state.item.img4 && (
								<Image
									cloudName="dcjvlrsp9"
									publicId={`launchaventure/project/${this.state.item.img4}`}
								/>
								)}
		          </div> 
		          <div className="details">
		            {/* <div className="cat"><Link to="/blog-details">Business</Link></div> */}
		            <p dangerouslySetInnerHTML={{
                    __html: this.state.item.desc4,
                  }}/>
		            <div className="row">
		              
		            </div>
		          </div>
              <div className="thumb">
              {this.state.item.img5 && (
								<Image
									cloudName="dcjvlrsp9"
									publicId={`launchaventure/project/${this.state.item.img5}`}
								/>
								)}
		          </div> 
		          <div className="details">
		            {/* <div className="cat"><Link to="/blog-details">Business</Link></div> */}
		            <p dangerouslySetInnerHTML={{
                    __html: this.state.item.desc5,
                  }}/>
		            <div className="row">
		              
		            </div>
		          </div>
              

		        </div>
		        
		        
		      </div>
			  {/* <div className="col-lg-4">
            <aside className="sidebar-area">
              <div className="widget widget-author text-center">
               
                <div className="details">
                  <h5>Dolor eorem ipsum sit amet Lorem ipsum</h5>
                  <p>Dolor eorem ipsum sit amet Lorem ipsum</p>
                  <h5>Dolor eorem ipsum sit amet Lorem ipsum</h5>
                  
                </div>        
              </div>
              <div className="widget widget-news">
                <h5 className="widget-title">Reward 1</h5>
                <div className="single-news-wrap media">
                  <div className="thumb">
                    <img src={publicUrl+"assets/img/blog/5.png"} alt="img" />
                  </div>
                  <div className="media-body align-self-center">
                    <h6><Link to="/blog-details">Dolor eorem ipsum sit amet Lorem ipsum</Link></h6>
                    <p className="date"><i className="far fa-calendar-alt" />25 Aug 2020</p>
                  </div>
                </div>
                <div className="single-news-wrap media">
                  <div className="thumb">
                    <img src={publicUrl+"assets/img/blog/6.png"} alt="img" />
                  </div>
                  <div className="media-body align-self-center">
                    <h6><Link to="/blog-details">Responsive Web And Desktop Develope</Link></h6>
                    <p className="date"><i className="far fa-calendar-alt" />25 Aug 2020</p>
                  </div>
                </div>
                <div className="single-news-wrap media">
                  <div className="thumb">
                    <img src={publicUrl+"assets/img/blog/7.png"} alt="img" />
                  </div>
                  <div className="media-body align-self-center">
                    <h6><Link to="/blog-details">Admin Web is Django Highlig Models</Link></h6>
                    <p className="date"><i className="far fa-calendar-alt" />25 Aug 2020</p>
                  </div>
                </div>
              </div>
              <div className="widget widget-category">
                <h5 className="widget-title">Reward 2</h5>
                <ul>
                  <li><Link to="/blog">Design <span>26</span></Link></li>
                  <li><Link to="/blog">Villa House <span>20</span></Link></li>
                  <li><Link to="/blog">Business <span>21</span></Link></li>
                  <li><Link to="/blog">Global World <span>31</span></Link></li>
                  <li><Link to="/blog">Technology <span>12</span></Link></li>
                  <li><Link to="/blog">Ui Design <span>12</span></Link></li>
                </ul>
              </div>
              <div className="widget widget-instagram">
                <h5 className="widget-title">Reward 3</h5>
                <div className="widget-instagram-inner">
                  <div className="row custom-gutters-10">
                    <div className="col-4">
                      <div className="thumb">
                        <img src={publicUrl+"assets/img/instagram/1.png"} alt="img" />
                        <i className="fab fa-instagram" />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="thumb">
                        <img src={publicUrl+"assets/img/instagram/2.png"} alt="img" />
                        <i className="fab fa-instagram" />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="thumb">
                        <img src={publicUrl+"assets/img/instagram/3.png"} alt="img" />
                        <i className="fab fa-instagram" />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="thumb">
                        <img src={publicUrl+"assets/img/instagram/4.png"} alt="img" />
                        <i className="fab fa-instagram" />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="thumb">
                        <img src={publicUrl+"assets/img/instagram/5.png"} alt="img" />
                        <i className="fab fa-instagram" />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="thumb">
                        <img src={publicUrl+"assets/img/instagram/6.png"} alt="img" />
                        <i className="fab fa-instagram" />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="thumb">
                        <img src={publicUrl+"assets/img/instagram/7.png"} alt="img" />
                        <i className="fab fa-instagram" />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="thumb">
                        <img src={publicUrl+"assets/img/instagram/8.png"} alt="img" />
                        <i className="fab fa-instagram" />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="thumb">
                        <img src={publicUrl+"assets/img/instagram/9.png"} alt="img" />
                        <i className="fab fa-instagram" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="widget widget-add">
                <div className="thumb">
                  <img src={publicUrl+"assets/img/other/5.png"} alt="img" />
                </div>
              </div>
              <div className="widget widget-tags">
                <h5 className="widget-title">Reward 5</h5>
                <div className="tagcloud">
                  <Link to="/blog">Popular</Link>
                  <Link to="/blog">Art</Link>
                  <Link to="/blog">Business</Link>
                  <Link to="/blog">Design</Link>
                  <Link to="/blog">Creator</Link>
                  <Link to="/blog">CSS</Link>
                  <Link to="/blog">Planing</Link>
                  <Link to="/blog">Creative</Link>
                </div>   
              </div>
            </aside>
          </div> */}
		    </div>
		  </div>
		</div>

    )
  }
}

export default withRouter(Blog);
