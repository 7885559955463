import React from 'react';
import Navbar from './global-components/navbar-v3';
import Footer from './global-components/footer';

const Refund = () => {
    return <div>
        <Navbar />
        <div className="container pd-top-90 pd-bottom-120">
        <h1>Cancellation & Refund Policy</h1>
    <p><strong>Last updated on 23-09-2024 14:29:35</strong></p>

    <p><strong>LAV BUSINESS SOLUTIONS PRIVATE LIMITED</strong> is committed to providing a flexible cancellation policy. Below are the terms of the policy:</p>

    <ul>
        <li><span class="highlight">Cancellation Requests:</span> Cancellations will only be considered if requested immediately after placing the order. If the order has already been communicated to the vendors or shipping has been initiated, the cancellation request may not be accepted.</li>

        <li><span class="highlight">Non-Cancellable Items:</span> Perishable items like flowers and eatables cannot be canceled. However, refunds or replacements may be processed if the customer proves that the delivered product's quality is unsatisfactory.</li>

        <li><span class="highlight">Damaged or Defective Products:</span> If you receive a damaged or defective product, it should be reported to the Customer Service team within <strong>7 days</strong> of receipt. The merchant will verify the issue before any action is taken.
            <br/>Additionally, if the product does not meet your expectations or differs from what was displayed on the site, it must be reported within <strong>7 days</strong> of receipt for review by the Customer Service team, who will decide on the appropriate action.</li>

        <li><span class="highlight">Products with Manufacturer Warranty:</span> For products with manufacturer warranties, please refer to the manufacturer for any issues.</li>

        <li><span class="highlight">Refunds:</span> If a refund is approved by <strong>LAV BUSINESS SOLUTIONS PRIVATE LIMITED</strong>, the amount will be processed within <strong>3-5 days</strong> to the end customer.</li>
    </ul>
    </div>
        <Footer />
    </div>
}

export default Refund

