import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import parse from 'html-react-parser';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { serviceUrl } from '../../constant';

// const validatePassword = (value) => {
// 	let error;
// 	if (!value) {
// 	  error = 'Please enter your password';
// 	} else if (value.length < 4) {
// 	  error = 'Password must be longer than 3 characters';
// 	}
// 	return error;
//   };
  
//   const validateName = (value) => {
// 	let error;
// 	if (!value) {
// 	  error = 'Please enter your name';
// 	} else if (!/^[a-zA-Z ]{3,250}$/.test(value)) {
// 	  error = 'Name must be have only character & Name must be longer than 3 character';
// 	}
// 	return error;
//   };
//   const validateCity = (value) => {
// 	let error;
// 	if (!value) {
// 	  error = 'Please enter your city';
// 	} else if (!/^[a-zA-Z ]{2,250}$/.test(value)) {
// 	  error = 'City must be have only character & City must be longer than 2 character';
// 	}
// 	return error;
//   };
//   const validateMobile = (value) => {
// 	let error;
// 	if (!value) {
// 	  error = 'Please enter your Mobile Number';
// 	} else if (!/^\d{10}$/.test(value)) {
// 	  error = 'Mobile Number must be of 10 digit';
// 	}
// 	return error;
//   };
  const validateEmail = (value) => {
	let error;
	if (!value) {
	  error = 'Please enter your email address';
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
	  error = 'Invalid email address';
	}
	return error;
  };

class SignUp extends Component {
	state={
		email:"",
		password:"",
		name:"",
		type: false, 
		city:"",
		mobile:"",
	}

	componentDidMount() {

     const $ = window.$;

     $('body').addClass( 'bg-gray' );

 	}
	 onSubmit= async(values)=>{
		// e.preventDefault();
		if (values.email !== '') {
			console.log("value",values);
			try {
				let project2 = await axios.post(`${serviceUrl}investor/getresetpasswordlink`, values);
				// const project = project2.data.data.items[0].projects;
				//   this.setState ({project});
				  console.log(project2);
				  if(project2.status===200){
					// NotificationManager.success('Registration successful', `Please login!!`);
					// alert("registration successful, please login again!!!");
					toast.success(`Please Check Your Mail to reset!!!`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "light",
						});
					this.props.history.push('/sign-in');
					// console.log("sucess");
				}
			
				// Work with the response...
			} catch (err) {
				if (err.response) {
					// The client was given an error response (5xx, 4xx)
					console.log(err.response.data);
					// NotificationManager.error( err.response.data.message, 'Error message', 5000);
					toast.error(err.response.data.message, {
						position: "top-center",
						autoClose: 8000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "light",
						});
						toast.error(err.response.data.errors[0].message, {
							position: "top-center",
							autoClose: 8000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: "light",
							});

					   console.log(err.response.status);
					console.log(err.response.headers);
				} else if (err.request) {
					// The client never received a response, and the request was never left
					console.log(err.request);
				} else {
					// Anything else
					console.log('Error', err.message);
				}
			}
		}
	}

    render() {

        // let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="signup-page-area pd-top-100">
			  <div className="container">
			    <div className="row justify-content-center">
			      <div className="col-xl-6 col-lg-7">
			        {/* <form className="signin-inner"> */}
					<Formik   initialValues={{
					email: 'receiver@launchmybusiness.in',
					}}
					onSubmit={(values)=>this.onSubmit(values)}>
              {({ errors, touched }) => (
                <Form className="signin-inner">
			          <div className="row">
			    
			            <div className="col-12">
			              <label className="single-input-inner style-bg-border">Email
						  <Field
                      name="email"
                      validate={validateEmail}
					//   onChange={(e)=>this.setState({email:e.target.value})}
                    />
                    {errors.email && touched.email && (
                      <div className='warn'>
                        {errors.email}
                      </div>
                    )}
			              </label>
			            </div>
			            <div className="col-12 mb-4">
			              <button className="btn btn-base w-100">Reset Password</button>
			            </div>
			            <div className="col-12">
			              <span>Already a member?  </span>
			              <Link to="/sign-in"><strong> Sign In</strong></Link>
			            </div>
			          </div>
			        {/* </form> */}
					</Form>
              )}
            </Formik>
			      </div>
			    </div>
				{/* <ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
				/> */}
			  </div>
			</div>

        }
}

export default withRouter(SignUp)