import React from 'react';
import Navbar from './global-components/navbar-v3';
import PageHeader from './global-components/page-header';
// import Property from './section-components/property-page';
import Project from './section-components/project-funding';
import Footer from './global-components/footer';

const PropertyPage = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Project Funding" />
        {/* <Property /> */}
        <Project />
        <Footer />
    </div>
}

export default PropertyPage

