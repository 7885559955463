import React from 'react';
// import { ToastContainer } from 'react-toastify';
// import "react-toastify/dist/ReactToastify.css";
import Navbar from './global-components/navbar-v3';
import Banner from './section-components/banner-v2';
// import Product from './section-components/product-v3';
import Service from './section-components/service-v3';
// import Property from './section-components/property';
import About from './section-components/about';
import Testimonial from './section-components/testimonial-v2';
// import Client from './section-components/client-v2';
// import Agent from './section-components/agent-v2';
import Cta from './section-components/call-to-action';
// import ProductCategories from './section-components/product-categories';
// import LatestNews from './blog-components/latest-news-v2';
import Footer from './global-components/footer';

const Home_V2 = () => {
    return <div>
        <Navbar />
        <Banner />
        <About/>
        {/* <Product /> */}
        <Service />
        {/* <Property /> */}
        <Testimonial />
        <Cta/>
        {/* <Client /> */}
        {/* <Agent /> */}
        {/* <ProductCategories /> */}
        {/* <LatestNews /> */}
        {/* <ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
				/> */}
        <Footer />
    </div>
}

export default Home_V2

