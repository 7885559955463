import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class TestimonialV2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="testimonial-area bg-overlay pd-top-118 pd-bottom-120 bg-overlay" 
	// style={{background: 'url(assets/img/bg/2.png)'}}
	>
			  <div className="container">
			    <div className="section-title style-white text-center">
			      <h6>Why our Clients love LAV </h6>
			      <h2>How we have helped our Customers</h2>
			      {/* <p>Lorem ipsum dolor  amet, consectetur adipisicing elit Lorem ipsum dolor sit amet, </p> */}
			    </div>
			    <div className="testimonial-slider-2 owl-carousel text-center">
			      <div className="item">
			        <div className="single-testimonial-inner style-three text-center">
			          <div className="thumb main-thumb">
			            <img src={publicUrl+"assets/img/testimonial/Surbhi.jpg"} alt="img" />
			          </div>
			          <div className="details">
			            <h6 className="name">Surbhi Chauhan</h6>
			            {/* <span className="designation">Guest</span> */}
			            <p>Launch A Venture is a game-changer for startups. I recently launched my startup and was able to
find the right investors in my locality with just a few clicks. The platform is user-friendly and makes it
easy to connect with potential investors in no time. It has saved me countless hours of research and
networking, and I strongly recommend it to any aspiring entrepreneur.</p>
			            {/* <div className="rating-inner">
			              <i className="fas fa-star" />
			              <i className="fas fa-star" />
			              <i className="fas fa-star" />
			              <i className="fas fa-star" />
			              <i className="far fa-star" aria-hidden="true" />
			            </div> */}
			          </div>
			        </div>
			      </div>
			      <div className="item">
			        <div className="single-testimonial-inner style-three text-center">
			          <div className="thumb main-thumb">
			            <img src={publicUrl+"assets/img/testimonial/Vishal.jpg"} alt="img" />
			          </div>
			          <div className="details">
			            <h6 className="name">Ankit Khede</h6>
			            {/* <span className="designation">Guest</span> */}
			            <p>I recently used Launch A Venture to find the right investors and resources for my startup. It was so
easy to use and I had no problem understanding how it all worked. I was able to connect with great
investors who were the perfect fit for my company in no time. I highly recommend Launch A Venture
if you're looking for a platform that can help you quickly and easily find investors in your locality!</p>
			            {/* <div className="rating-inner">
			              <i className="fas fa-star" />
			              <i className="fas fa-star" />
			              <i className="fas fa-star" />
			              <i className="fas fa-star" />
			              <i className="far fa-star" aria-hidden="true" />
			            </div> */}
			          </div>
			        </div>
			      </div>
			      <div className="item">
			        <div className="single-testimonial-inner style-three text-center">
			          <div className="thumb main-thumb">
			            <img src={publicUrl+"assets/img/testimonial/Vinayak.jpg"} alt="img" />
			          </div>
			          <div className="details">
			            <h6 className="name">Vinayak Chandra</h6>
			            {/* <span className="designation">Guest</span> */}
			            <p>Launch A Venture has been a great partner for me and my business. They provide an array of
services that have been essential to the success of my business. From funding to legal assistance -
they have everything covered. They are knowledgeable, reliable and always willing to go the extra
mile for their clients. I highly recommend Launch A Venture!</p>
			            {/* <div className="rating-inner">
			              <i className="fas fa-star" />
			              <i className="fas fa-star" />
			              <i className="fas fa-star" />
			              <i className="fas fa-star" />
			              <i className="far fa-star" aria-hidden="true" />
			            </div> */}
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>

        }
}

export default TestimonialV2