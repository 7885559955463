import React from 'react';
import Navbar from './global-components/navbar-v3';
import PageHeader from './global-components/page-header';
import ResetPassword from './section-components/reset-password';
import Footer from './global-components/footer';

const SignUpPage = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Reset Password"  />
        <ResetPassword />
        <Footer />
    </div>
}

export default SignUpPage

