import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class About extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div id="about" className="about-area bg-gray pd-top-90 pd-bottom-120">
              <div className="container">
                <div className="single-about-inner about-line-right bg-white">
                  <div className="row no-gutter">
                    <div className="col-lg-4 order-lg-12">
                      <div className="thumb" style={{background: 'url('+publicUrl+'assets/img/other/about.jpeg)'}} />
                    </div>
                    <div className="col-lg-8 order-lg-1">
                      <div className="details">
                        <div className="section-title mb-4">
                          {/* <h6>We are offring the best real estate</h6> */}
                          <h2>Discover Exclusive Investment Opportunities from your Locality</h2>
                          <p>Launch A Venture is a platform which connects Startups with Investors' Rights from their Localities.</p>
                          <p>It also provides all the required Services for Launching a Venture from Idea to Implementation including Company Registration, ITR Filings, GST Registration and Filings, Trademark Registration, Food License, Software Services, Legal Services, PR & Branding </p>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <ul className="single-list-inner">
                              <li>Startup Funding</li>
                              <li>Startup Registration</li>
                              <li>Legal Services</li>
                              {/* <li>Lorem ipsum dolor manga</li> */}
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <ul className="single-list-inner">
                              <li>Software Services</li>
                              <li>PR & Branding</li>
                              <li>Marketing & Strategies</li>
                            </ul>
                          </div>
                        </div>  
                        {/* <a className="btn btn-base" href="#">SEE MORE</a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

        }
}

export default About