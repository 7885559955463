import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {Table, Button} from 'reactstrap';
import parse from 'html-react-parser';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { serviceUrl, tokenName, iName } from '../../constant';
import { getToken } from '../../globalfunction';

class Funding extends Component {
	state={
		project:[],
		summary:[],
		// this.onSubmit()=this.onSubmit.bind(this),
	}
	async investedListFetch(){
		getToken();
		try {
			let project2 = await axios.get(`${serviceUrl}prj/log/my/list?limit=10&page_no=1`);
			const project = project2.data.data.items[0].upgrades;
		  	this.setState ({project});
		  	console.log(project);
		
			// Work with the response...
		} catch (err) {
			if (err.response) {
				// The client was given an error response (5xx, 4xx)
				console.log(err.response.data);
       			console.log(err.response.status);
        		console.log(err.response.headers);
			} else if (err.request) {
				// The client never received a response, and the request was never left
				console.log(err.request);
			} else {
				// Anything else
				console.log('Error', err.message);
			}
		}
	  }

	componentDidMount() {
		this.investedListFetch();

     const $ = window.$;

     $( 'body' ).addClass( 'bg-gray' );

 	}

    render() {

        // let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="signin-page-area pd-top-100 ">
			  <div className="container">
			    <div className="row justify-content-center">
					
				{/* <ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
				/> */}
				<h5>Log Table </h5>
			      <Table responsive>
					<thead>
						<tr>
						<th>
							Date
						</th>
						<th>
							Project Name
						</th>
						<th>
							City
						</th>
						<th>
							Action
						</th>
						</tr>
					</thead>
					<tbody>
						{this.state.project.map((project)=>{
							return(
							<tr>
								<td>
									{project.date}
								</td>
								<td>
								{project.title}
								</td>
								<td>
								{project.city}
								</td>
								<td>
									<Button color="primary"><Link to={`/log/${project.id}`}>See Detail</Link></Button>
								</td>
						</tr>
						)
						})}
						
					</tbody>
					</Table>
					
			    </div>
			  </div>
			  
			</div>
        }
}

export default withRouter(Funding)