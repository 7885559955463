import React, { useEffect, useState } from 'react';
import { Image } from 'cloudinary-react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import axios from 'axios';
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';
import { Button, Label, Card, CardBody, Col, Row, Input } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { getToken } from '../../globalfunction';
import { serviceUrl } from '../../constant';

const apiUrl = `${serviceUrl}investor`;

const AddNewProject = ({ error }) => {
	const history= useHistory();
  const [picture1, setPicture1] = useState(null);
  const [pictureUrl1, setPictureUrl1] = useState(null);
  const [picture2, setPicture2] = useState(null);
  const [pictureUrl2, setPictureUrl2] = useState(null);
  const [picture3, setPicture3] = useState(null);
  const [pictureUrl3, setPictureUrl3] = useState(null);
  const [mainImg, setMainImg] = useState(null);
  const [mainImgUrl, setMainImgUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const onSubmit = async(events, errors, values) => {
    // console.log(errors);
    console.log(values);
    const { pan, aadhar } = values;
    const pay = {
      aadhar,
      pan,
      pan_image: pictureUrl1,
      aadhar_f: pictureUrl2,
      aadhar_b: pictureUrl3,
      selfie: mainImgUrl,
    };

    if (errors.length === 0 || values.title) {
      setLoading(false);
      setTimeout(() => {
        setLoading(true);
      }, 5000);
      getToken();
	  console.log(pay);
  // eslint-disable-next-line no-return-await
  try {
    const response = await axios.post(`${apiUrl}/kyc`, 
      pay,
    );
    console.log(response);
	if(response.status===200){
		toast.success(`KYC Submitted Successfully`, {
			position: "top-center",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
			});
		history.push('/l/project');
		// console.log("sucess");
	}
	//   console.log(login);

	// Work with the response...
} catch (err) {
	if (err.response) {
		// The client was given an error response (5xx, 4xx)
		//   console.log("err response",err.response.status);
		//   NotificationManager.error( err.response.data.message, 'Error message', 5000);
		if(err.response.data.errors?.length > 0 ){
		toast.error(err.response.data.errors[0].message, {
			position: "top-center",
			autoClose: 8000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
			});
		}
		if(err.response.status === 403){
		toast.error(err.response.data.data.kind, {
			position: "top-center",
			autoClose: 8000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
			});	
	}
		if(err.response.data.message){
			toast.error(err.response.data.message, {
				position: "top-center",
				autoClose: 8000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
				});
		}
		//    console.log(err.response.status);
		//   console.log(err.response.headers);
	} else if (err.request) {
		// The client never received a response, and the request was never left
		console.log("err, req",err.request);
	} else {
		// Anything else
		console.log('Error', err.message);
	}
}
    }
  };
  const onImageUpload1 = async () => {
    // console.log(imgData);
    getToken();
    const task = new FormData();
    task.append('pan_image', picture1);
    console.log(picture1);
    try {
      const response = await axios.post(`${apiUrl}/pan/upload/file`, task, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('response', response);
      if (response.status === 200) {
        setPictureUrl1(response.data.data.items[0].img);
      }
    } catch (err) {
    //   NotificationManager.error(
    //     'Please call us at ....',
    //     err.response.statusText,
    //     3000,
    //     null,
    //     null,
    //     ''
    //   );
    }
  };
  const onImageUpload2 = async () => {
    // console.log(imgData);
    getToken();
    const task = new FormData();
    task.append('aadhar_f_image', picture2);
    console.log(picture2);
    try {
      const response = await axios.post(
        `${apiUrl}/aadhar/f/upload/file`,
        task,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      console.log('response', response);
      if (response.status === 200) {
        setPictureUrl2(response.data.data.items[0].img);
      }
    } catch (err) {
    //   NotificationManager.error(
    //     'Please call us at ....',
    //     err.response.statusText,
    //     3000,
    //     null,
    //     null,
    //     ''
    //   );
    }
  };
  const onImageUpload3 = async () => {
    // console.log(imgData);
    getToken();
    const task = new FormData();
    task.append('aadhar_b_image', picture3);
    console.log(picture3);
    try {
      const response = await axios.post(
        `${apiUrl}/aadhar/b/upload/file`,
        task,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      console.log('response', response);
      if (response.status === 200) {
        setPictureUrl3(response.data.data.items[0].img);
      }
    } catch (err) {
    //   NotificationManager.error(
    //     'Please call us at ....',
    //     err.response.statusText,
    //     3000,
    //     null,
    //     null,
    //     ''
    //   );
    }
  };

  const onImageUploadMain = async () => {
    // console.log(imgData);
    getToken();
    const task = new FormData();
    task.append('selfie_image', mainImg);
    console.log(mainImg);
    try {
      const response = await axios.post(`${apiUrl}/selfie/upload/file`, task, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('response', response);
      if (response.status === 200) {
        setMainImgUrl(response.data.data.items[0].img);
      }
    } catch (err) {
    //   NotificationManager.error(
    //     'Please call us at ....',
    //     err.response.statusText,
    //     3000,
    //     null,
    //     null,
    //     ''
    //   );
    }
  };
  const onChangePicture1 = (e) => {
    if (e.target.files[0]) {
      setPicture1(e.target.files[0]);
      // console.log(picture);
      const reader = new FileReader();
      // reader.onload = () => {
      // };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const onChangePicture2 = (e) => {
    if (e.target.files[0]) {
      setPicture2(e.target.files[0]);
      // console.log(picture);
      const reader = new FileReader();
      // reader.onload = () => {
      // };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const onChangePicture3 = (e) => {
    if (e.target.files[0]) {
      setPicture3(e.target.files[0]);
      // console.log(picture);
      const reader = new FileReader();
      // reader.onload = () => {
      // };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const onChangePictureMain = (e) => {
    if (e.target.files[0]) {
      setMainImg(e.target.files[0]);
      // console.log(picture);
      const reader = new FileReader();
      // reader.onload = () => {
      // };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  useEffect(() => {
    if (picture1) {
      onImageUpload1();
    }
  }, [picture1]);
  useEffect(() => {
    if (picture2) {
      onImageUpload2();
    }
  }, [picture2]);
  useEffect(() => {
    if (picture3) {
      onImageUpload3();
    }
  }, [picture3]);
  useEffect(() => {
    if (mainImg) {
      onImageUploadMain();
    }
  }, [mainImg]);
  useEffect(() => {
    if (error) {
    //   NotificationManager.warning(
    //     error,
    //     'Project Add Error',
    //     3000,
    //     null,
    //     null,
    //     ''
    //   );
    }
  }, [error]);
  return (
    <Card className="mb-5 container">
      <CardBody>
        <h6 className="mb-4">KYC Detail</h6>
        <AvForm
          className="av-tooltip tooltip-label-right"
          onSubmit={(event, errors, values) => onSubmit(event, errors, values)}
        >
          <Row>
            <Col lg="6">
              <AvGroup className="error-l-100 error-t-negative">
                <Label>Aadhar</Label>
                <AvField
                  name="aadhar"
                  type="text"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Please enter aadhar number',
                    },
                    number: {
                      value: true,
                      errorMessage: 'Aadhar must be a number',
                    },
                  }}
                />
              </AvGroup>
            </Col>

            <Col lg="6">
              <AvGroup className="error-l-100 error-t-negative">
                <Label>Pan Number</Label>
                <AvField
                  name="pan"
                  type="text"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Please enter PAN number',
                    },
                    // number: {
                    //   value: true,
                    //   errorMessage: 'PAN must be a number',
                    // },
                  }}
                />
              </AvGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <AvGroup className="error-t-negative">
                <Label>Selfie</Label>
                <Input
                  id="profilePic"
                  name="selfie_image"
                  type="file"
                  onChange={onChangePictureMain}
                />
                {/* <img src={`data:;base64,${imgData}`} alt="" /> <h3>{pictureUrl}</h3> */}

                {mainImgUrl && (
                  <Image
                    cloudName="dcjvlrsp9"
                    publicId={`launchaventure/investor/selfie/${mainImgUrl}`}
                  />
                )}
                <h3>{pictureUrl1}</h3>
              </AvGroup>
            </Col>
          </Row>
          <AvGroup className="error-t-negative">
            <Label>PAN Image</Label>
            <Input
              id="profilePic"
              name="pan_image"
              type="file"
              onChange={onChangePicture1}
            />
            {/* <img src={`data:;base64,${imgData}`} alt="" /> <h3>{pictureUrl}</h3> */}

            {pictureUrl1 && (
              <Image
                cloudName="dcjvlrsp9"
                publicId={`launchaventure/investor/pan/${pictureUrl1}`}
              />
            )}
            <h3>{pictureUrl1}</h3>
          </AvGroup>
          <AvGroup className="error-t-negative">
            <Label>Front Of Aadhar Image</Label>
            <Input
              id="profilePic"
              name="aadhar_f_image"
              type="file"
              onChange={onChangePicture2}
            />
            {/* <img src={`data:;base64,${imgData}`} alt="" /> <h3>{pictureUrl}</h3> */}

            {pictureUrl2 && (
              <Image
                cloudName="dcjvlrsp9"
                publicId={`launchaventure/investor/aadhar/front/${pictureUrl2}`}
              />
            )}
            <h3>{pictureUrl2}</h3>
          </AvGroup>
          <AvGroup className="error-t-negative">
            <Label>Back Of Aadhar Image</Label>
            <Input
              id="profilePic"
              name="aadhar_b_image"
              type="file"
              onChange={onChangePicture3}
            />
            {/* <img src={`data:;base64,${imgData}`} alt="" /> <h3>{pictureUrl}</h3> */}

            {pictureUrl3 && (
              <Image
                cloudName="dcjvlrsp9"
                publicId={`launchaventure/investor/aadhar/back/${pictureUrl3}`}
              />
            )}
            <h3>{pictureUrl3}</h3>
          </AvGroup>
          {loading && <Button color="primary">Submit</Button>}
        </AvForm>
      </CardBody>
    </Card>
  );
};


export default withRouter(AddNewProject);
