import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { iName, serviceUrl, tokenName } from '../../constant';
import { HashLink } from 'react-router-hash-link';
import { getName,  getToken } from '../../globalfunction';
const apiUrl = `${serviceUrl}investor/kyc/status`;
class NavbarV3 extends Component {
	state={
		status:""
	}
	onLogout=()=>{
		localStorage.clear();
		this.props.history.push('/');

	}
	
	kycStatus = async ()=>{
    getToken();
    const response = await axios.get(`${apiUrl}`, {});
    if (response.status === 200 && response.data.data.items[0].status.length) {
        console.log(response.data.data.items[0].status[0].status)
      const status =response.data.data.items[0].status[0].status;
	  this.setState({status:status});
      return status;
    }
        
}
componentDidMount() {
	this.kycStatus();

	}

    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
		// console.log("kyc",kycStatus);
        let imgattr = 'logo'
        let anchor = '#'
        return (
        	<div className="navbar-area navbar-area-3">
			  <nav className="navbar navbar-expand-lg">
			    <div className="container nav-container">
			      <div className="responsive-mobile-menu">
			        <button className="menu toggle-btn d-block d-lg-none" data-target="#dkt_main_menu" aria-expanded="false" aria-label="Toggle navigation">
			          <span className="icon-left" />
			          <span className="icon-right" />
			        </button>
			      </div>
			      <div className="logo">
			        <Link to="/"><img src={publicUrl+"assets/img/logo.png" } alt="img" /></Link>
			      </div>
			      <div className="nav-right-part nav-right-part-mobile">
				  {getName() ?<div  style={{color:"white", fontSize:"1.2em"}}>{getName()}</div>:<Link className="btn btn-base" to="/sign-in">Login</Link>}
			      </div>
			      <div className="collapse navbar-collapse" id="dkt_main_menu">
			        <ul className="navbar-nav menu-open text-center">
					{getName() ?
					  <li className="menu-item-has-children current-menu-item">
						<a href="#">Dashboard</a>
						<ul className="sub-menu"> 
						<li><HashLink to="/funding">Invested</HashLink></li>
						<li><HashLink to="/ideation">Interested</HashLink></li>
						<li><HashLink to="/transaction">Transaction</HashLink></li>
						<li><HashLink to="/log">Log</HashLink></li>
						{this.state.status !==1 && this.state.status !==0?<li><HashLink to="/kyc/add">Kyc</HashLink></li>:""}
						</ul> 
					  </li>:""}
			          <li>
			            <HashLink to="/">Home</HashLink>
			            {/* <ul className="sub-menu">
			              <li><Link to="/">Home 01</Link></li>
			              <li><Link to="/home-v2">Home 02</Link></li>
			              <li><Link to="/home-v3">Home 03</Link></li>
			            </ul> */}
			          </li>
			          <li>
			            <HashLink to="/#about">About</HashLink>
			            {/* <ul className="sub-menu">
			              <li><Link to="/property">Property</Link></li>
			              <li><Link to="/property-grid">Property Grid</Link></li>
			              <li><Link to="/property-details">Property Details</Link></li>
			            </ul> */}
			          </li>
					  <li><HashLink to="/#services">Services</HashLink></li>
			          {/* <li className="menu-item-has-children current-menu-item">
			            <a href="#">Pages</a>
			            <ul className="sub-menu">
			              <li><Link to="/about">About</Link></li>
			              <li><Link to="/team">Team</Link></li>
			              <li><Link to="/sign-in">Sign In</Link></li>
			              <li><Link to="/sign-up">Sign Up</Link></li>
			              <li><Link to="/add-property">Add Property</Link></li>
			            </ul>
			          </li> */}
			          <li >
			            <Link to="/l/project">Projects</Link>
			            {/* <ul className="sub-menu">
			              <li><Link to="/blog">Blog</Link></li>
			              <li><Link to="/blog-details">Blog Details</Link></li>
			            </ul> */}
			          </li>
			          <li><HashLink to="/#contact">Contact</HashLink></li>
					  
			        </ul>
			      </div>
			      <div className="nav-right-part nav-right-part-desktop">
			        <ul>
			          {/* <li><a className="search" href="#"><i className="fa fa-search" /></a></li> */}
					  {getName() ? 
					  <><li className="menu-item-has-children current-menu-item">
					  		<div  style={{color:"white", fontSize:"1.5em"}}>{getName()}</div>
						</li>{" "}
						<button onClick={this.onLogout} className="btn" style={{borderRadius:"50px", color:"white", background:"#cc3300"}}>Logout</button>
						</>
					  :
			          <li><Link className="btn btn-base" to="/sign-in">Login</Link></li>}
			        </ul>
			      </div>
			    </div>
			  </nav>
			</div>
        )
    }
}


export default withRouter(NavbarV3)